import * as React from "react";

import styles from "./layout.module.css";

import KeyIcon from "../../icons/key-icon";
import LogoutIcon from "../../icons/logout-icon";
import authService from "../../services/api/auth.service";
import storageService from "../../services/storage.service";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";

export default function LayoutModule({
    children
}) {
    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <Header />
            </div>
            <div className={styles.body}>
                { children }
            </div>
        </div>
    );
};