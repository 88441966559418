// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form_loginForm__XeO0H {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 4px #aaa;

    animation-name: login-form_FormAnim__BuUhk;
    animation-duration: 0.6s;
    animation-iteration-count: 1;

    background-color: #fefefe;
    width: 25%;
    min-width: 200px;
}

.login-form_loginForm__XeO0H > * {
    width: 100%;
}

.login-form_loginForm__XeO0H input {
    margin-bottom: 10px;
}

.login-form_button__HGRFl {
    width: 100%;
}

.login-form_loginError__MhuCw {
    color: crimson;
    font-size: 13px;
    margin-bottom: 10px;
}

@keyframes login-form_FormAnim__BuUhk {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-form_header__NEjlD {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
}

    .login-form_header__NEjlD h2 {
        width: 100%;
        text-align: center;
        color: #212121;
    }`, "",{"version":3,"sources":["webpack://./src/modules/login-form/login-form.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,wBAAwB;;IAExB,0CAAwB;IACxB,wBAAwB;IACxB,4BAA4B;;IAE5B,yBAAyB;IACzB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;AACxB;;IAEI;QACI,WAAW;QACX,kBAAkB;QAClB,cAAc;IAClB","sourcesContent":[".loginForm {\n    display: flex;\n    padding: 20px;\n    border-radius: 10px;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    box-shadow: 0 0 4px #aaa;\n\n    animation-name: FormAnim;\n    animation-duration: 0.6s;\n    animation-iteration-count: 1;\n\n    background-color: #fefefe;\n    width: 25%;\n    min-width: 200px;\n}\n\n.loginForm > * {\n    width: 100%;\n}\n\n.loginForm input {\n    margin-bottom: 10px;\n}\n\n.button {\n    width: 100%;\n}\n\n.loginError {\n    color: crimson;\n    font-size: 13px;\n    margin-bottom: 10px;\n}\n\n@keyframes FormAnim {\n    0% {\n        opacity: 0;\n        transform: translateY(10px);\n    }\n    100% {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n\n.header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-bottom: 20px;\n}\n\n    .header h2 {\n        width: 100%;\n        text-align: center;\n        color: #212121;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": `login-form_loginForm__XeO0H`,
	"FormAnim": `login-form_FormAnim__BuUhk`,
	"button": `login-form_button__HGRFl`,
	"loginError": `login-form_loginError__MhuCw`,
	"header": `login-form_header__NEjlD`
};
export default ___CSS_LOADER_EXPORT___;
