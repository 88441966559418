import * as React from "react";

import styles from "./rule-item.module.css";
import LinkIcon from "../../icons/link-icon";
import copyToClipboard from "../../utils/clipboard-helper";

export default function RuleItemComponent({
    id,
    title,
    description,
    disabled,
    creationTime,
    updateTime,
    onRemoveClick,
    onClick,
    deleteable
}) {
    const deleteRef = React.useRef();
    const linkRef = React.useRef();

    const onClickItem = e => {
        if(!onClick || e.target === deleteRef.current || e.target === linkRef.current) return;
        onClick();
    };

    const onLinkClick = () => {
        const url = `${process.env.REACT_APP_API_URL}/engine/evaluate/${id}`;

        if(navigator.clipboard && navigator.clipboard.writeText)
            navigator.clipboard.writeText(url);
        else
            copyToClipboard(url);
    };

    return (
        <div className={styles.ruleItem} onClick={onClickItem}>
            <div className={styles.header}>
                <div className={styles.infos}>
                    <span className={styles.title}>{ title }</span>
                    <span className={styles.id}>@{ id }</span>
                    {
                        disabled === true && (
                            <span className={styles.status}>(désactivé)</span>
                        )
                    }
                </div>
                <div className={styles.buttons}>
                    <span
                        className={styles.link}
                        ref={linkRef}
                        onClick={() => onLinkClick()}
                    >
                        Copier l'endpoint
                    </span>
                </div>
            </div>
            <div className={styles.body}>
                {
                    typeof description === 'string' && description.trim().length > 0 && (
                        <>
                            <div className={styles.informationDot} title={"Descriptif de la règle"}>
                                <span>i</span>
                            </div>
                            <p className={styles.description}>{ description.split('\n').map(text => (<> <span className={styles.descriptionItem}>{text}</span><br/> </>)) }</p>
                        </>
                    )
                }
            </div>
            <div className={styles.bottom}>
                <div className={styles.times}>
                    <span>Créer : { creationTime }</span>
                    <span>Dernière modification : { updateTime }</span>
                </div>
                {
                    deleteable && (
                        <span 
                            className={styles.delete} 
                            onClick={onRemoveClick} 
                            ref={deleteRef}
                        >
                            Supprimer
                        </span>
                    )
                }
            </div>
        </div>
    );
};