// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__4-vvU {
    outline: none!important;
    border: none;
    padding: 4px 8px;
    font-size: 16px;
    text-align: center;
    color: #fefefe;
    background-color: #aaa;

    border-radius: 100px;
    box-shadow: 0 0 3px #aaa;

    transition: opacity 0.2s, transform 0.2s;
}

    .button_button__4-vvU:hover:not(.button_disabled__wtrq3) {
        opacity: 0.85;
        cursor: pointer;
    }

    .button_button__4-vvU:active:not(.button_disabled__wtrq3) {
        transform: scale(0.95);
    }

.button_disabled__wtrq3 {
    opacity: 0.3!important;
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,sBAAsB;;IAEtB,oBAAoB;IACpB,wBAAwB;;IAExB,wCAAwC;AAC5C;;IAEI;QACI,aAAa;QACb,eAAe;IACnB;;IAEA;QACI,sBAAsB;IAC1B;;AAEJ;IACI,sBAAsB;AAC1B","sourcesContent":[".button {\n    outline: none!important;\n    border: none;\n    padding: 4px 8px;\n    font-size: 16px;\n    text-align: center;\n    color: #fefefe;\n    background-color: #aaa;\n\n    border-radius: 100px;\n    box-shadow: 0 0 3px #aaa;\n\n    transition: opacity 0.2s, transform 0.2s;\n}\n\n    .button:hover:not(.disabled) {\n        opacity: 0.85;\n        cursor: pointer;\n    }\n\n    .button:active:not(.disabled) {\n        transform: scale(0.95);\n    }\n\n.disabled {\n    opacity: 0.3!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__4-vvU`,
	"disabled": `button_disabled__wtrq3`
};
export default ___CSS_LOADER_EXPORT___;
