import * as React from "react";

import styles from "./rules-editor-panel.module.css";
import TextInputComponent from "../../components/text-input/text-input";
import ButtonComponent from "../../components/button/button";

export default function RulesEditorPanel({
    editingRule,
    onApply,
    onCancel,
    disableEdit,
    updateable
}) {
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [id, setId] = React.useState('');
    const [createMode, setCreateMode] = React.useState(false);
    const [status, setStatus] = React.useState(true);

    const [titleError, setTitleError] = React.useState(false);

    React.useEffect(() => {
        if(!editingRule) return;
        setTitle(editingRule.name);
        setDescription(editingRule.description === undefined ? '' : editingRule.description);
        setStatus(editingRule.disabled === true ? false : true);
        setCreateMode(!editingRule.id);
        setId(!editingRule.id ? '-' : `@${editingRule.id}`);
    }, [editingRule]);

    React.useEffect(() => {
        setTitleError(false);
    }, [editingRule, title]);

    const onApplyClick = () => {
        if(!onApply) return;
        if(onApply({ title, description, disabled: !status }) === false) setTitleError(true);
    };

    return (
        <div className={`${styles.rulesEditorPanel} ${!editingRule ? styles.hidden : ''}`}>
            <TextInputComponent className={styles.input} label={"Id"} value={id} disabled />
            <TextInputComponent className={styles.input} label={"Nom de la règle"} value={title} onChange={value => setTitle(value)} />
            <TextInputComponent className={styles.textArea} label={"Description de la règle"} placeholder={"Aucune"} value={description} onChange={value => setDescription(value)} paragraphMode />

            {
                editingRule && editingRule.id && (
                    <div className={styles.statusWrapper}>
                        <label className={styles.statusLabel}>Status de la règle : </label>
                        <ButtonComponent 
                            color={status ? "green" : "crimson"}
                            onClick={() => updateable && setStatus(flag => !flag)}
                        >
                            { status ? "Activé" : "Désactivé" }
                        </ButtonComponent>
                    </div>
                )
            }

            {
                titleError && (
                    <label className={styles.titleError}>Erreur : le nom de cette règle existe déjà</label>
                )
            }
            <div className={styles.buttons}>
                {
                    updateable ? (
                        <>
                            <ButtonComponent 
                                color={createMode ? "dodgerblue" : "green"}
                                onClick={onApplyClick}
                                disabled={disableEdit}
                            >
                                {
                                    createMode ? 'Créer' : 'Modifier'
                                }
                            </ButtonComponent>
                            <ButtonComponent 
                                color="crimson"
                                onClick={() => onCancel && onCancel()}
                                disabled={disableEdit}
                            >
                                Annuler
                            </ButtonComponent>
                        </>
                    ) : (
                        <ButtonComponent 
                            color="crimson"
                            onClick={() => onCancel && onCancel()}
                            disabled={disableEdit}
                        >
                            Retour
                        </ButtonComponent>
                    )
                }
            </div>
        </div>
    );
};