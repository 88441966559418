import * as React from 'react';

import styles from "./button.module.css";

export default function ButtonComponent({
    children,
    onClick,
    color,
    textColor,
    className,
    disabled
}) {
    return (
        <button 
            className={`${styles.button} ${className ? className : ''} ${disabled ? styles.disabled : ''}`} 
            style={{ 
                backgroundColor: color ? color : undefined,
                color: textColor ? textColor : undefined
            }} 
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};