// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.black-screen_blackScreen__1wj4b {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);

    visibility: visible;
    opacity: 1;

    -webkit-backdrop-filter: blur(0);

            backdrop-filter: blur(0);

    transition: opacity 0.3s, visibility 0.3s, -webkit-backdrop-filter 0.3s;

    transition: opacity 0.3s, visibility 0.3s, backdrop-filter 0.3s;

    transition: opacity 0.3s, visibility 0.3s, backdrop-filter 0.3s, -webkit-backdrop-filter 0.3s;
}

.black-screen_fixed__UwinT {
    position: fixed;
}

.black-screen_blur__Bxsfz {
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.black-screen_hidden__D5-3\\+ {
    opacity: 0;
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/black-screen/black-screen.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,oCAAoC;;IAEpC,mBAAmB;IACnB,UAAU;;IAEV,gCAAwB;;YAAxB,wBAAwB;;IAExB,uEAA+D;;IAA/D,+DAA+D;;IAA/D,6FAA+D;AACnE;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".blackScreen {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    z-index: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    background-color: rgba(0, 0, 0, 0.5);\n\n    visibility: visible;\n    opacity: 1;\n\n    backdrop-filter: blur(0);\n\n    transition: opacity 0.3s, visibility 0.3s, backdrop-filter 0.3s;\n}\n\n.fixed {\n    position: fixed;\n}\n\n.blur {\n    backdrop-filter: blur(3px);\n}\n\n.hidden {\n    opacity: 0;\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blackScreen": `black-screen_blackScreen__1wj4b`,
	"fixed": `black-screen_fixed__UwinT`,
	"blur": `black-screen_blur__Bxsfz`,
	"hidden": `black-screen_hidden__D5-3+`
};
export default ___CSS_LOADER_EXPORT___;
