import BlackScreenComponent from '../../components/black-screen/black-screen';
import SpinnerComponent from '../../components/spinner/spinner';
import RuleIcon from '../../icons/rule-icon';
import styles from './organizations-list.module.css';

export default function OrganizationsListComponent({
    items,
    show,
    onItemClick,
    onSelfClick,
    loading
}) {
    return (
        <div className={`${styles.organizationsList} ${!show ? styles.hidden : ''}`}>
            <OrganizationItem id={0} label={"Mes règles"} onClick={onSelfClick} icon={<RuleIcon />} />
            {
                items && items.map(item => (
                    <OrganizationItem id={item.id} label={item.name} onClick={onItemClick} />
                ))
            }
            <BlackScreenComponent
                show={loading}
                blur
            >
                <SpinnerComponent />
            </BlackScreenComponent>
        </div>
    );
};

function OrganizationItem({
    id,
    label,
    icon,
    onClick
}) {
    return (
        <div className={styles.organizationItem} onClick={() => onClick && onClick(id)}>
            <span className={styles.itemFirstLetter}>
                {
                    icon ? (
                        icon
                    ) : (
                        <span>{label[0]}</span>
                    )
                }
            </span>
            <span className={styles.itemLabel}>{label}</span>
        </div>
    );
};