import ApiService from "../../utils/api-service";
import authService from "./auth.service";

class OrganizationService extends ApiService {
    constructor(url) {
        super(url);
    }

    _getToken() {
        return authService.getActiveToken();
    }

    async getOrganizations(withPermissions = false) {
        return await this.generateResponse(
            await this.request(`/organizations${withPermissions ? `?withPermissions=true` : ''}`)
        );
    }

    async getOrganizationGroups(organizationId, withRules = false) {
        return await this.generateResponse(
            await this.request(`/organizations/${organizationId}/groups${withRules ? `?withRules=true` : ''}`)
        );
    }

    async getOrganizationRules(organizationId, withPermissions = false) {
        return await this.generateResponse(
            await this.request(`/organizations/${organizationId}/rules${withPermissions ? `?withPermissions=true` : ''}`)
        );
    }
};

const organizationService = new OrganizationService(process.env.REACT_APP_API_URL);
export default organizationService;