import HomeIcon from '../../icons/home-icon';
import styles from './rules-header.module.css';

export default function RulesHeader({
    selectedOrganization,
    selectedGroup,
    editingRule,
    onOrganizationClick,
    onGroupClick,
    onHomeClick,
    onEditingRuleClick
}) {
    return (
        <div className={styles.rulesHeader}>
            <div className={styles.path}>
                <HomeIcon className={styles.homeIcon} onClick={onHomeClick} />
                {
                    selectedOrganization !== undefined && (
                        <div className={styles.pathItemWrapper}>
                            <SeparatorComponent />
                            <PathItemComponent text={selectedOrganization} onClick={onOrganizationClick} />
                        </div>
                    )
                }
                {
                    selectedGroup !== undefined && (
                        <div className={styles.pathItemWrapper}>
                            <SeparatorComponent />
                            <PathItemComponent text={selectedGroup} onClick={onGroupClick} />
                        </div>
                    )
                }
                {
                    editingRule !== undefined && (
                        <div className={styles.pathItemWrapper}>
                            <SeparatorComponent />
                            <PathItemComponent text={editingRule} onClick={onEditingRuleClick} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

function SeparatorComponent() {
    return (
        <span className={styles.separator}>/</span>
    );
};

function PathItemComponent({
    text,
    onClick
}) {
    return (
        <span className={styles.pathItem} onClick={onClick}>{text}</span>
    );
}