import ApiService from "../../utils/api-service";
import authService from "./auth.service";

class RuleService extends ApiService {
    constructor(url) {
        super(url);
    }

    _getToken() {
        return authService.getActiveToken();
    }

    async getRules(withContents = false) {
        return await this.generateResponse(
            await this.request(`/rules${withContents ? '?withContents=true' : ''}`)
        );
    }

    async createRule(groupId, title, description, rule) {
        return await this.generateResponse(
            await this.request(
                '/rules', 
                'POST',
                {
                    name: title,
                    description: description,
                    rule: typeof rule === 'object' ? btoa(JSON.stringify(rule)) : btoa(rule),
                    group: groupId
                }
            )
        );
    }

    async updateRule(id, { title, description, disabled }, rule) {
        return await this.generateResponse(
            await this.request(
                `/rules/${id}`, 
                'PUT',
                {
                    name: title,
                    description: description,
                    disabled: disabled === undefined ? false : disabled,
                    rule: typeof rule === 'object' ? btoa(JSON.stringify(rule)) : btoa(rule)
                }
            )
        );
    }

    async deleteRule(id) {
        return await this.generateResponse(
            await this.request(
                `/rules/${id}`, 
                'DELETE'
            )
        );
    }
};

const ruleService = new RuleService(process.env.REACT_APP_API_URL);
export default ruleService;