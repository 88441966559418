import * as React from "react";

import styles from "./modal.module.css";

export default function ModalComponent({
    show,
    onClose,
    fixed,
    blur,
    className,
    children,
    title
}) {
    const wrapperRef = React.useRef();

    const onClick = e => {
        if(e.target === wrapperRef.current && onClose)
            onClose();
    };

    return (
        <div 
            onClick={onClick}
            ref={wrapperRef}
            className={`${styles.modalWrapper} ${fixed ? styles.fixed : ''} ${!show ? styles.hidden : ''} ${blur ? styles.blur : ''}`}
        >
            <div className={`${styles.modal} ${className ? className : ''}`}>
                {
                    title && (
                        <div className={styles.header}>
                            <h3>{title}</h3>
                            <hr />
                        </div>
                    )
                }
                <div className={styles.body}>
                    {children}  
                </div>
            </div>
        </div>
    );
};