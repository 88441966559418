import ApiService from "../../utils/api-service";
import authService from "./auth.service";

class UserService extends ApiService {
    constructor(url) {
        super(url);
    }

    _getToken() {
        return authService.getActiveToken();
    }

    async getApiKey() {
        return await this.generateResponse(
            await this.request(`/user/key`)
        );
    }

    async generateApiKey() {
        return await this.generateResponse(
            await this.request(
                '/user/generate-key', 
                'PUT'
            )
        );
    }
};

const userService = new UserService(process.env.REACT_APP_API_URL);
export default userService;