import React from 'react';
import BlackScreenComponent from '../../components/black-screen/black-screen';
import SpinnerComponent from '../../components/spinner/spinner';
import TextInputComponent from '../../components/text-input/text-input';
import styles from './groups-list.module.css';
import ButtonComponent from '../../components/button/button';
import ModalComponent from '../../components/modal/modal';

export default function GroupsListComponent({
    items,
    show,
    onItemClick,
    onCreateClick,
    onDeleteClick,
    createable
}) {
    const [groupName, setGroupName] = React.useState('');
    const [deleteMode, setDeleteMode] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(null);

    const onGroupClick = (groupId) => {
        if(deleteMode) {
            setConfirmDelete(groupId);
            return;
        }

        if(onItemClick) onItemClick(groupId);
    };

    React.useEffect(() => {
        if(!show) {
            setDeleteMode(false);
            setConfirmDelete(null);
        }
    }, [show]);

    return (
        <div className={`${styles.groupsList} ${!show ? styles.hidden : ''}`}>
            {
                createable && (
                    <div className={styles.searchBar}>
                        <div className={styles.createForm}>
                            <TextInputComponent className={styles.createInput} value={groupName} onChange={value => setGroupName(value)} placeholder="Nom de groupe" />
                            <ButtonComponent 
                                className={styles.createButton}
                                color={"green"}
                                onClick={() => {
                                    if(onCreateClick) onCreateClick(groupName);
                                    setGroupName('');
                                }}
                            >
                                Créer
                            </ButtonComponent>
                        </div>
                        <ButtonComponent
                            color={deleteMode ? "red" : "crimson"}
                            className={styles.deleteButton}
                            onClick={() => setDeleteMode(state => !state)}
                        >
                            {
                                !deleteMode ? "Activer le mode supression" : "Désactiver le mode supression"
                            }
                        </ButtonComponent>
                        <hr />
                    </div>
                )
            }
            <ul className={styles.list}>
                {
                    items && items.map(item => (
                        <GroupItem id={item.id} label={item.name} onClick={onGroupClick} deleteMode={deleteMode} />
                    ))
                }
            </ul>
            <ModalComponent
                show={confirmDelete != null}
                className={styles.modal}
                title="Etes-vous sur de vouloir supprimer ce groupe ?"
            >
                <div className={styles.removeButtons}>
                    <ButtonComponent
                        color="green"
                        onClick={() => {
                            if(onDeleteClick) onDeleteClick(confirmDelete);
                            setConfirmDelete(null);
                        }}
                    >
                        Confirmer
                    </ButtonComponent>
                    <ButtonComponent
                        color="crimson"
                        onClick={() => setConfirmDelete(null)}
                    >
                        Annuler
                    </ButtonComponent>
                </div>
            </ModalComponent>
        </div>
    );
};

function GroupItem({
    id,
    label,
    onClick,
    deleteMode,
    loading
}) {
    return (
        <div className={`${styles.groupItem} ${deleteMode ? styles.deleteMode : ''}`} onClick={() => onClick && onClick(id)}>
            <span className={styles.itemFirstLetter}>
                <span>{label[0]}</span>
            </span>
            <span className={styles.itemLabel}>{label}</span>
            <BlackScreenComponent
                show={loading}
                blur
            >
                <SpinnerComponent />
            </BlackScreenComponent>
        </div>
    );
};