import * as React from "./black-screen.module.css";

import styles from "./black-screen.module.css";

export default function BlackScreenComponent({
    show,
    fixedPosition,
    blur,
    children
}) {
    return (
        <div className={`${styles.blackScreen} ${fixedPosition ? styles.fixed : ''} ${!show ? styles.hidden : ''} ${blur ? styles.blur : ''}`}>
            { children }
        </div>
    );
};