export default class ApiService {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    _getToken() {
        return '';
    }

    request(url, method = 'GET', body = null, headers = null, useToken = true) {
        return new Promise(async resolve => {
            const head = { 
                'Authorization': useToken ? `Bearer ${this._getToken()}` : undefined,
                'Content-Type': body ? 'application/json' : undefined
            };
            if(headers) Object.entries(headers).forEach(([k,v]) => head[k] = v);
    
            fetch(`${this.endpoint}${url}`, 
                { headers: head, method: method, body: !body ? undefined : (typeof body === 'string' ? body : JSON.stringify(body)) })
            .then(resp => resolve(resp))
            .catch(err => resolve(new Response(null, { status: 500 })));  
        }); 
    }

    parseJson(response) {
        return new Promise(async (resolve) => {
            let err = false;
            const parsedObj = await response.json().catch(() => err = true);
            resolve(err ? undefined : parsedObj);
        });
    }

    generateResponse(response, parseToJson = true) {
        return new Promise(async (resolve) => {
            const 
                parsedObj = parseToJson ? await this.parseJson(response) : undefined,
                isError = parsedObj && typeof parsedObj === 'object' && Object.keys(parsedObj).length === 1 && parsedObj.message !== undefined
            ;

            resolve({ 
                err: (parseToJson && parsedObj === undefined) || isError || response.status !== 200, 
                response: response, 
                data: isError ? undefined : parsedObj, 
                errorMessage: isError ? parsedObj.message : undefined 
            });
        });
    };
};