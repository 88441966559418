// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-editor-tool_rulesEditorTool__MXN-u {
    width: 60%;
    height: 100%;
    position: relative;
    box-shadow: 1px 0 3px #aaa;
    z-index: 1;

    transition: width 0.4s;
}

.rules-editor-tool_editing__1UfFH {
    width: 70%;
}

.rules-editor-tool_label__xhXiU {
    color: #fefefe;
    font-size: 18px;
    padding: 0 6px;
    text-align: center;
}

.rules-editor-tool_createLabel__LB2YF {
    text-decoration: underline;
    color: dodgerblue;
    transition: color 0.2s;
}

    .rules-editor-tool_createLabel__LB2YF:hover {
        color: skyblue;
        cursor: pointer;
    }`, "",{"version":3,"sources":["webpack://./src/modules/rules-editor-tool/rules-editor-tool.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,UAAU;;IAEV,sBAAsB;AAC1B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,sBAAsB;AAC1B;;IAEI;QACI,cAAc;QACd,eAAe;IACnB","sourcesContent":[".rulesEditorTool {\n    width: 60%;\n    height: 100%;\n    position: relative;\n    box-shadow: 1px 0 3px #aaa;\n    z-index: 1;\n\n    transition: width 0.4s;\n}\n\n.editing {\n    width: 70%;\n}\n\n.label {\n    color: #fefefe;\n    font-size: 18px;\n    padding: 0 6px;\n    text-align: center;\n}\n\n.createLabel {\n    text-decoration: underline;\n    color: dodgerblue;\n    transition: color 0.2s;\n}\n\n    .createLabel:hover {\n        color: skyblue;\n        cursor: pointer;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rulesEditorTool": `rules-editor-tool_rulesEditorTool__MXN-u`,
	"editing": `rules-editor-tool_editing__1UfFH`,
	"label": `rules-editor-tool_label__xhXiU`,
	"createLabel": `rules-editor-tool_createLabel__LB2YF`
};
export default ___CSS_LOADER_EXPORT___;
