// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_spinner__a7DuJ {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: dodgerblue;

    animation-name: spinner_SpinnerAnim__nXEx2;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes spinner_SpinnerAnim__nXEx2 {
    0% {
        opacity: 0.9;
        transform: scale(1);
    }
    60% {
        opacity: 0.75;
        transform: scale(0.75);
    }
    100% {
        opacity: 0.9;
        transform: scale(1);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,4BAA4B;;IAE5B,0CAA2B;IAC3B,sBAAsB;IACtB,mCAAmC;AACvC;;AAEA;IACI;QACI,YAAY;QACZ,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,sBAAsB;IAC1B;IACA;QACI,YAAY;QACZ,mBAAmB;IACvB;AACJ","sourcesContent":[".spinner {\n    border-radius: 50%;\n    width: 30px;\n    height: 30px;\n    background-color: dodgerblue;\n\n    animation-name: SpinnerAnim;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n}\n\n@keyframes SpinnerAnim {\n    0% {\n        opacity: 0.9;\n        transform: scale(1);\n    }\n    60% {\n        opacity: 0.75;\n        transform: scale(0.75);\n    }\n    100% {\n        opacity: 0.9;\n        transform: scale(1);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `spinner_spinner__a7DuJ`,
	"SpinnerAnim": `spinner_SpinnerAnim__nXEx2`
};
export default ___CSS_LOADER_EXPORT___;
