import { DecisionGraph, JdmConfigProvider } from "@gorules/jdm-editor";
import '@gorules/jdm-editor/dist/style.css';
import * as React from "react";

import styles from "./rules-editor-tool.module.css";
import BlackScreenComponent from "../../components/black-screen/black-screen";

export default function RulesEditorTool({
    graph,
    onGraphUpdated,
    onCreate,
    onEditingGraph,
    editing,
    canCreate,
    labelText
}) {
    return (
        <div className={`${styles.rulesEditorTool} ${editing ? styles.editing : ''}`}>
            <JdmConfigProvider>
                <DecisionGraph
                    value={graph}
                    onChange={value => onGraphUpdated && onGraphUpdated(value)}
                    onEditGraph={onEditingGraph}
                />
            </JdmConfigProvider>
            <BlackScreenComponent show={graph == null} blur>
                {
                    labelText !== undefined ? (
                        <label className={styles.label}>
                            {labelText}
                        </label>
                    ) : (
                        canCreate ? (
                            <label className={styles.label}>
                                Veuillez sélectionner une Règle ou bien en <span className={styles.createLabel} onClick={onCreate}>créer</span> une.
                            </label>
                        ) : (
                            <label className={styles.label}>
                                Veuillez sélectionner une Règle.
                            </label>
                        )
                    )
                }
            </BlackScreenComponent>
        </div>
    );
};