import LoginForm from "../modules/login-form/login-form";

const PageStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export default function LoginPage() {
    return (
        <div style={PageStyle}>
            <LoginForm />
        </div>
    );
};