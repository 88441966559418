import * as React from "./spinner.module.css";

import styles from "./spinner.module.css";

export default function SpinnerComponent({
    className,
    color,
    size
}) {
    return (
        <span 
            className={`${styles.spinner} ${className ? className :''}`} 
            style={{ width: size, height: size, backgroundColor: color }}
        >
        </span>
    );
};