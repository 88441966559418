export function objectCopy(obj) {
    const output = {};
    for(const [key, value] of Object.entries(obj)) {
        output[key] = typeof value === 'object' ? objectCopy(value) : value;
    }
    return output;
};

export function decodeBase64Object(str) {
    try {
        return JSON.parse(atob(str));
    }
    catch(err) {
    }
    return null;
};