import ApiService from "../../utils/api-service";
import storageService from "../storage.service";

class AuthService extends ApiService {
    constructor(url) {
        super(url);
        this._token = null;

        this._onAuthCallbacks = [];
    }

    _getToken() {
        return this._token;
    }

    getActiveToken() {
        return this._token;
    }

    async login(email, password) {
        const resp = await this.generateResponse(await this.request('/auth/login', 'POST', { email, password }));

        if(!resp.err && resp.data && resp.data.token) {
            this._token = resp.data.token;
            return true;
        }

        return false;
    }

    async checkToken(token) {
        const resp = await this.generateResponse(await this.request('/auth/check', 'GET', null, { 'Authorization': `Bearer ${token}` }, false));

        if(!resp.err && resp.data && resp.data.success) {
            this._token = token;
            return true;
        }

        return false;
    }

    async logout() {
        this._token = null;
    }
};

const authService = new AuthService(process.env.REACT_APP_API_URL);
export default authService;