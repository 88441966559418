// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-panel_rulesPanel__zFZ2D {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: width 0.4s;
}

.rules-panel_editing__Lq0IS {
    width: 30%;
}

.rules-panel_removeButtons__TmZvD {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

    .rules-panel_removeButtons__TmZvD > * {
        width: 45%;
    }

.rules-panel_modal__eh6VV {
    width: 75%;
}`, "",{"version":3,"sources":["webpack://./src/modules/rules-panel/rules-panel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;IAEI;QACI,UAAU;IACd;;AAEJ;IACI,UAAU;AACd","sourcesContent":[".rulesPanel {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    width: 40%;\n    height: 100%;\n    overflow: hidden;\n    transition: width 0.4s;\n}\n\n.editing {\n    width: 30%;\n}\n\n.removeButtons {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n    .removeButtons > * {\n        width: 45%;\n    }\n\n.modal {\n    width: 75%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rulesPanel": `rules-panel_rulesPanel__zFZ2D`,
	"editing": `rules-panel_editing__Lq0IS`,
	"removeButtons": `rules-panel_removeButtons__TmZvD`,
	"modal": `rules-panel_modal__eh6VV`
};
export default ___CSS_LOADER_EXPORT___;
