// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-input_textInput__-sFun {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: opacity 0.3s;
}

.text-input_textInput__-sFun > * {
    width: 100%;
}

.text-input_textInput__-sFun label {
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 6px;
    color: #444;

    opacity: 1;

    transition: opacity 0.3s, margin-left 0.3s;
}

    .text-input_textInput__-sFun:focus-within label {
        opacity: 0.85;
        margin-left: 6px;
    }

.text-input_textInput__-sFun input, .text-input_textInput__-sFun textarea {
    outline: none!important;
    padding: 4px 8px;
    border-radius: 100px;
    border: none;
    box-shadow: 0 0 4px #888;
    transition: box-shadow 0.1s;
    color: #212121;
    font-size: 14px;
}

    .text-input_textInput__-sFun input:focus-visible, .text-input_textInput__-sFun textarea:focus-visible {
        box-shadow: 0 0 4px dodgerblue;
    }

.text-input_disabled__9CYiH {
    opacity: 0.8;
}

.text-input_textInput__-sFun textArea {
    resize: none;
    padding: 10px 8px;
    border-radius: 20px;
    overflow: overlay;
}`, "",{"version":3,"sources":["webpack://./src/components/text-input/text-input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,WAAW;;IAEX,UAAU;;IAEV,0CAA0C;AAC9C;;IAEI;QACI,aAAa;QACb,gBAAgB;IACpB;;AAEJ;IACI,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,wBAAwB;IACxB,2BAA2B;IAC3B,cAAc;IACd,eAAe;AACnB;;IAEI;QACI,8BAA8B;IAClC;;AAEJ;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".textInput {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    transition: opacity 0.3s;\n}\n\n.textInput > * {\n    width: 100%;\n}\n\n.textInput label {\n    font-weight: 500;\n    font-size: 11px;\n    margin-bottom: 6px;\n    color: #444;\n\n    opacity: 1;\n\n    transition: opacity 0.3s, margin-left 0.3s;\n}\n\n    .textInput:focus-within label {\n        opacity: 0.85;\n        margin-left: 6px;\n    }\n\n.textInput input, .textInput textarea {\n    outline: none!important;\n    padding: 4px 8px;\n    border-radius: 100px;\n    border: none;\n    box-shadow: 0 0 4px #888;\n    transition: box-shadow 0.1s;\n    color: #212121;\n    font-size: 14px;\n}\n\n    .textInput input:focus-visible, .textInput textarea:focus-visible {\n        box-shadow: 0 0 4px dodgerblue;\n    }\n\n.disabled {\n    opacity: 0.8;\n}\n\n.textInput textArea {\n    resize: none;\n    padding: 10px 8px;\n    border-radius: 20px;\n    overflow: overlay;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": `text-input_textInput__-sFun`,
	"disabled": `text-input_disabled__9CYiH`
};
export default ___CSS_LOADER_EXPORT___;
