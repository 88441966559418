// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_layout__JRinx {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.layout_header__FbVqi {
    width: 100%;
    height: 10vh;
}

.layout_body__cGMm- {
    height: 90vh;
    flex: 1 1;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/modules/layout/layout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,SAAO;IACP,WAAW;IACX,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".layout {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n\n.header {\n    width: 100%;\n    height: 10vh;\n}\n\n.body {\n    height: 90vh;\n    flex: 1;\n    width: 100%;\n    position: relative;\n\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_layout__JRinx`,
	"header": `layout_header__FbVqi`,
	"body": `layout_body__cGMm-`
};
export default ___CSS_LOADER_EXPORT___;
