import * as React from "react";

import styles from "./rules-panel.module.css";

import RulesList from "../rules-list/rules-list";
import RulesEditorPanel from "../rules-editor-panel/rules-editor-panel";
import BlackScreenComponent from "../../components/black-screen/black-screen";
import SpinnerComponent from "../../components/spinner/spinner";
import ModalComponent from "../../components/modal/modal";
import ButtonComponent from "../../components/button/button";

export default function RulesPanel({
    rules,
    loading,
    editingRule,
    disableEdit,
    onRuleEdit,
    onRuleRemove,
    onEditApply,
    onEditCancel,
    dontConfirmRemove,
    deleteable,
    updateable
}) {
    const [confirmRemove, setConfirmRemove] = React.useState(null);

    const removeRule = rule => {
        if(rule && onRuleRemove) onRuleRemove(rule);
        setConfirmRemove(null);
    };

    const onRuleRemoveClick = rule => {
        if(dontConfirmRemove) return removeRule(rule);
        setConfirmRemove(rule);
    };

    return (
        <div className={`${styles.rulesPanel} ${editingRule ? styles.editing : ''}`}>
            <RulesList 
                rules={rules}
                disabled={editingRule != null}
                onRuleEdit={onRuleEdit}
                onRuleRemove={onRuleRemoveClick}
                deleteable={deleteable}
            />
            <RulesEditorPanel
                editingRule={editingRule}
                onApply={(props) => onEditApply && onEditApply(props)}
                onCancel={() => onEditCancel && onEditCancel()}
                disableEdit={disableEdit}
                updateable={updateable}
            />
            <ModalComponent
                show={confirmRemove != null}
                className={styles.modal}
                title="Etes-vous sur de vouloir supprimer cette règle ?"
            >
                <div className={styles.removeButtons}>
                    <ButtonComponent
                        color="green"
                        onClick={() => removeRule(confirmRemove)}
                    >
                        Confirmer
                    </ButtonComponent>
                    <ButtonComponent
                        color="crimson"
                        onClick={() => setConfirmRemove(null)}
                    >
                        Annuler
                    </ButtonComponent>
                </div>
            </ModalComponent>
            <BlackScreenComponent
                show={loading}
                blur
            >
                <SpinnerComponent />
            </BlackScreenComponent>
        </div>
    );
};