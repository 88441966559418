import * as React from "react";

import styles from "./header.module.css";
import KeyIcon from "../../icons/key-icon";
import LogoutIcon from "../../icons/logout-icon";
import { useNavigate } from "react-router-dom";
import authService from "../../services/api/auth.service";
import storageService from "../../services/storage.service";
import ModalComponent from "../../components/modal/modal";
import TextInputComponent from "../../components/text-input/text-input";
import BlackScreenComponent from "../../components/black-screen/black-screen";
import SpinnerComponent from "../../components/spinner/spinner";
import userService from "../../services/api/user.service";
import ButtonComponent from "../../components/button/button";

export default function Header({
    onLogout
}) {
    const navigate = useNavigate();

    const [showKey, setShowKey] = React.useState(false);
    const [key, setKey] = React.useState('');
    const [fetchingKey, setFetchingKey] = React.useState(false);
    const [confirmMode, setConfirmMode] = React.useState(false);
    const [confirmValue, setConfirmValue] = React.useState('');

    const inputRef = React.useRef();

    const onLogoutClick = () => {
        if(onLogout) onLogout();

        authService.logout().then(() => {
            storageService.remove('token');
            navigate('/');
        });
    };

    const onKeyClick = () => {
        setKey('');
        setFetchingKey(true);
        setShowKey(true);

        userService.getApiKey().then(res => {
            setFetchingKey(false);

            if(res.data && res.data.key)
                setKey(res.data.key);
        });
    };

    const onKeyResetClick = () => {
        setConfirmMode(false);
        setConfirmValue('');

        if(confirmValue.toLowerCase() !== 'confirmer') return;

        setFetchingKey(true);

        userService.generateApiKey().then(res => {
            setFetchingKey(false);

            if(res.data && res.data.key)
                setKey(res.data.key);
        });
    };

    const onKeyCopy = () => {
        if(!inputRef.current) return;
        inputRef.current.select();
        document.execCommand('copy');
    };
    
    const onModalClose = () => {
        setShowKey(false);
        setConfirmMode(false);
        setConfirmValue('');
    };

    return (
        <div className={styles.header}>
            <h1>Rules Editor</h1>
            <div className={styles.icons}>
                <KeyIcon onClick={onKeyClick} title="Récupérer sa clé API" />
                <LogoutIcon className={styles.logoutIcon} title="Se déconnecter" onClick={onLogoutClick} />
            </div>
            <ModalComponent
                show={showKey}
                onClose={() => onModalClose()}
                fixed
                title="Clé API utilisateur"
                className={styles.modal}
            >
                <div className={styles.modalBodyWrapper}>
                    <div className={styles.keyWrapper}>
                        <TextInputComponent 
                            inputRef={inputRef} 
                            className={styles.keyInput} 
                            value={key} 
                            placeholder={fetchingKey ? "Chargement..." : "Aucune"}
                        />
                        <ButtonComponent
                            onClick={onKeyCopy}
                            color="dodgerblue"
                            disabled={fetchingKey || !key}
                        >
                            Copier
                        </ButtonComponent>
                    </div>
                    <p className={styles.example}>
                        Headers = {`{ "api-key": "Key <your_api_key>" }`} <br/> <br/>
                        response = request.post("{`${process.env.REACT_APP_API_URL}/engine/evaluate/example-id`}", headers=Headers)
                    </p>
                    {
                        confirmMode && (
                            <div className={`${styles.keyWrapper} ${styles.confirmWrapper}`}>
                                <TextInputComponent 
                                    className={styles.keyInput} 
                                    value={confirmValue}
                                    onChange={e => setConfirmValue(e)} 
                                    placeholder={`Tapez "confirmer" pour valider`}
                                />
                                <ButtonComponent
                                    onClick={onKeyResetClick}
                                    color="green"
                                >
                                    Confirmer
                                </ButtonComponent>
                            </div>
                        )
                    }
                    {
                        !confirmMode && (
                            <ButtonComponent
                                onClick={() => setConfirmMode(true)}
                                color="green"
                                disabled={fetchingKey}
                            >
                                Régénérer une clé
                            </ButtonComponent>
                        )
                    }
                </div>
                
                <BlackScreenComponent show={fetchingKey}>
                    <SpinnerComponent />
                </BlackScreenComponent>
            </ModalComponent>
        </div>
    );
};