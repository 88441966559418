import '@gorules/jdm-editor/dist/style.css';
import React from 'react';
import ruleService from '../services/api/rule.service';
import RulesEditor from '../modules/rules-editor-tool/rules-editor-tool';
import RulesList from '../modules/rules-list/rules-list';
import RulesEditorPanel from '../modules/rules-editor-panel/rules-editor-panel';
import RulesEditorTool from '../modules/rules-editor-tool/rules-editor-tool';
import RulesPanel from '../modules/rules-panel/rules-panel';
import { decodeBase64Object } from '../utils/object-helper';
import LayoutModule from '../modules/layout/layout';
import authService from '../services/api/auth.service';
import { useLocation, useNavigate } from 'react-router-dom';
import storageService from '../services/storage.service';
import BlackScreenComponent from '../components/black-screen/black-screen';
import SpinnerComponent from '../components/spinner/spinner';
import RulesHeader from '../modules/rules-header/rules-header';
import OrganizationsListComponent from '../modules/organizations-list/organizations-list';
import organizationService from '../services/api/organization.service';
import groupService from '../services/api/group.service';
import GroupsListComponent from '../modules/groups-list/groups-list';

const PageStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
};

export default function DashboardPage() {
    const location = useLocation();

    const navigate = useNavigate();

    const [rules, setRules] = React.useState(null);
    const [organizations, setOrganizations] = React.useState(null);
    const [groups, setGroups] = React.useState(null);

    const [fetchingData, setFetchingData] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(false);

    const [selectedOrganization, setSelectedOrganization] = React.useState(null);
    const [selectedGroup, setSelectedGroup] = React.useState(null);

    const [editingRule, setEditingRule] = React.useState(null);
    const [activeGraph, setActiveGraph] = React.useState(null);

    const [editingGraph, setEditingGraph] = React.useState(false);

    const getOrganizationById = (organizationId) => {
        if(organizationId === '0')
            return { id: '0', name: 'Mes règles', permissions: [ 'EDIT_GROUPS' ] };

        if(organizations && organizationId) {
            for(const organization of organizations)
                if(organization.id == organizationId) return organization;
        }

        return null;
    };

    const getGroupById = (groupId) => {
        if(groups && groupId) {
            for(const group of groups)
                if(group.id == groupId) return group;
        }
        return null;
    };

    const refreshRules = (groupId, onRefreshed = undefined, silentUpdate = false) => {
        if(!groupId) return;

        if(!silentUpdate) setFetchingData(true);
        groupService.getGroupRules(groupId).then(res => {
            if(!silentUpdate) setFetchingData(false);
            if(res.err || !res.data || typeof res.data !== 'object' || !(res.data instanceof Array)) {
                if(!silentUpdate) setFetchError(true);
                if(!silentUpdate) setRules(null);
                return;
            }

            const group = getGroupById(groupId);
            if(group) group.rules = res.data;

            setRules(res.data);

            if(onRefreshed) onRefreshed(res.data);
        });
    };

    const refreshOrganizations = (onRefreshed = undefined, silentUpdate = false) => {
        if(!silentUpdate) setFetchingData(true);
        organizationService.getOrganizations(true).then(res => {
            if(!silentUpdate) setFetchingData(false);
            if(res.err || !res.data || typeof res.data !== 'object' || !(res.data instanceof Array)) {
                if(!silentUpdate) setFetchError(true);
                if(!silentUpdate) setOrganizations(null);
                return;
            }
            setOrganizations(res.data);

            if(onRefreshed) onRefreshed(res.data);
        });
    };

    const fetchGroups = (organizationId = null, silentUpdate = false) => {
        if(!silentUpdate) setFetchingData(true);

        const promise = (organizationId === null || organizationId === '0') 
            ? groupService.getGroups(true) : organizationService.getOrganizationGroups(organizationId, true);

        promise.then(res => {
            if(!silentUpdate) setFetchingData(false);
            if(res.err || !res.data || typeof res.data !== 'object') {
                if(!silentUpdate) setFetchError(true);
                if(!silentUpdate) setGroups(null);
                return;
            }
            setGroups(res.data);
        });
    };

    React.useEffect(() => {
        setFetchingData(true);

        const onOrganizationsFetched = async (organizations) => {
            if(!location.search || !organizations || !organizations.length) return;

            try {
                const search = new URLSearchParams(location.search);
                
                const groupId = search.get('groupId');
                const createRule = search.get('createRule');
                const ruleId = search.get('ruleId');

                if(ruleId === null && groupId === null) return;

                const organizationGroups = {};

                // Fetch organizations groups
                for(const organization of organizations) {
                    try {
                        const groups = await organizationService.getOrganizationGroups(organization.id, true).catch(() => {});

                        if(!groups || !groups.length) continue;

                        organizationGroups[organization.id] = groups;
                    }
                    catch(err) {}
                }

                // Handle ruleId edition
                if(ruleId) {
                    for(const [organization, groups] of Object.entries(organizationGroups)) {
                        for(const group of groups) {
                            for(const rule of group.rules) {
                                if(rule.id != ruleId) continue;
    
                                setSelectedOrganization(organization.id);
                                setGroups(groups);
                                setSelectedGroup(group.id);
                                setRules(group.rules);
                                onRuleEdit(rule);
                                return;
                            }
                        }
                    }
                }
                else { // Handle group
                    for(const [organization, groups] of Object.entries(organizationGroups)) {
                        const matchingGroups = groups.filter(grp => grp.id == groupId);
                        if(matchingGroups.length == 0) continue;

                        setSelectedOrganization(organization.id);
                        setGroups(groups);
                        setSelectedGroup(matchingGroups[0].id);
                        setRules(matchingGroups[0].rules);

                        // Handle create rule
                        if(createRule == 'true') {
                            setEditingRule({
                                name: ''
                            });
                            setActiveGraph({});
                        }

                        return;
                    }
                }
            }
            catch(err) {}
        };

        if(authService.getActiveToken())
            return refreshOrganizations(onOrganizationsFetched);

        let onFailed = () => {
            storageService.remove('token');
            navigate('/');
        };

        let token = null;

        // Use url's token
        if(location.search) {
            try {
                const search = new URLSearchParams(location.search);      
                const urlToken = search.get('token');

                if(urlToken) {
                    onFailed = () => {
                        alert("Token d'authentification invalide, vous allez être redirigé.");
                        navigate('/');
                    };

                    try {
                        token = atob(urlToken);
                    }
                    catch(err) {
                        // If base64 decode fails, call onFailed callback
                        onFailed();
                        return;
                    }
                }
            }
            catch(err) {}
        }

        if(!token) token = storageService.get('token');

        if(!token) return onFailed();

        authService.checkToken(token).then(flag => {
            if(flag) return refreshOrganizations(onOrganizationsFetched);
            onFailed();
        });
    }, []);

    const onRuleEdit = (rule) => {
        const decodedContent = decodeBase64Object(rule.content);

        if(!decodedContent)
            return console.log(`Failed to decode the rule's content "${rule.name}" (${rule.id})`);

        setEditingRule(rule);
        setActiveGraph(decodedContent);
    };

    const onRuleRemove = (rule) => {
        setEditingRule(null);
        setActiveGraph(null);

        ruleService.deleteRule(rule.id).then(() => refreshRules(selectedGroup));
    };

    const onEditApply = ({
        title,
        description,
        disabled
    }) => {
        if(!selectedGroup || !rules) return;

        title = title.trim();

        if(editingRule && activeGraph) {
            if(editingRule.name != title && rules.filter(rule => rule.name == title).length > 0)
                return false;

            setFetchingData(true);
            if(!editingRule.id)
                ruleService.createRule(selectedGroup, title, description, activeGraph).finally(() => refreshRules(selectedGroup));
            else 
                ruleService.updateRule(editingRule.id, { title, description, disabled }, activeGraph).finally(() => refreshRules(selectedGroup));
        }

        setEditingRule(null);
        setActiveGraph(null);
    };

    const onEditCancel = () => {
        setEditingRule(null);
        setActiveGraph(null);
    };

    const onCreateRequest = () => {
        setEditingRule({
            name: ''
        });
        setActiveGraph({});
    };

    const onGroupCreate = (groupName) => {
        if(selectedOrganization === null) return;

        setFetchingData(true);
        groupService.createGroup(groupName, selectedOrganization === '0' ? undefined : selectedOrganization).then(() => {
            fetchGroups(selectedOrganization === '0' ? null : selectedOrganization);
        });
    };

    const currentGroup = selectedGroup ? getGroupById(selectedGroup) : null;
    const currentOrganization = selectedOrganization ? getOrganizationById(selectedOrganization) : null;

    return (
        <LayoutModule>
            <RulesHeader
                selectedOrganization={currentOrganization ? currentOrganization.name : undefined}
                selectedGroup={currentGroup ? currentGroup.name : undefined}
                editingRule={editingRule ? editingRule.name : undefined}
                onOrganizationClick={() => {
                    onEditCancel();
                    setSelectedGroup(null);
                    setRules(null);
                }}
                onGroupClick={() => {
                    onEditCancel();
                }}
                onHomeClick={() => {
                    onEditCancel();
                    setSelectedOrganization(null);
                    setSelectedGroup(null);
                    setRules(null);
                }}
            />
            <div style={PageStyle}>
                <OrganizationsListComponent
                    items={organizations}
                    show={!selectedOrganization}
                    onItemClick={organization => {
                        setGroups(null);
                        setSelectedOrganization(organization);
                        fetchGroups(organization);
                    }}
                    onSelfClick={() => {
                        setGroups(null);
                        setSelectedOrganization('0');
                        fetchGroups(null);
                    }}
                    loading={fetchingData}
                />
                <GroupsListComponent
                    items={groups}
                    show={!selectedGroup && selectedOrganization != null}
                    onItemClick={group => {
                        if(!groups) return;

                        const matchingRules = groups.filter(g => g.id == group).map(g => g.rules);
                        if(matchingRules.length > 0) setRules(matchingRules[0]);
                        
                        setSelectedGroup(group);
                    }}
                    onCreateClick={onGroupCreate}
                    loading={fetchingData}
                    createable={currentOrganization ? currentOrganization.permissions.includes('EDIT_GROUPS') : false}
                    onDeleteClick={groupId => {
                        groupService.deleteGroup(groupId).then(() => fetchGroups(selectedOrganization));
                    }}
                />
                <RulesPanel
                    rules={rules}
                    loading={fetchingData}
                    editingRule={editingRule}
                    onRuleEdit={onRuleEdit}
                    onRuleRemove={onRuleRemove}
                    onEditApply={onEditApply}
                    onEditCancel={onEditCancel}
                    disableEdit={editingGraph}
                    deleteable={currentGroup ? currentGroup.permissions.includes('DELETE') : false}
                    updateable={currentGroup ? currentGroup.permissions.includes('EDIT') : false}
                />
                <RulesEditorTool
                    graph={activeGraph}
                    onGraphUpdated={graph => setActiveGraph(graph)}
                    onCreate={onCreateRequest}
                    onEditingGraph={flag => setEditingGraph(flag)}
                    editing={editingRule}
                    canCreate={currentGroup ? currentGroup.permissions.includes('EDIT') : false}
                    labelText={
                        !selectedOrganization ? 'Veuillez sélectionner une Organisation.' : (!selectedGroup ? 'Veuillez sélectionner un Groupe.' : undefined)
                    }
                />
            </div>
        </LayoutModule>
    );
};