import ApiService from "../../utils/api-service";
import authService from "./auth.service";

class GroupService extends ApiService {
    constructor(url) {
        super(url);
    }

    _getToken() {
        return authService.getActiveToken();
    }

    async getGroups(withRules = false) {
        return await this.generateResponse(
            await this.request(`/groups${withRules ? `?withRules=true` : ''}`)
        );
    }

    async getGroupRules(id) {
        return await this.generateResponse(
            await this.request(`/groups/${id}/rules`)
        );
    }

    async createGroup(name, organization = undefined) {
        return await this.generateResponse(
            await this.request(
                '/groups', 
                'POST',
                {
                    name,
                    organization
                }
            )
        );
    }

    async deleteGroup(id) {
        return await this.generateResponse(
            await this.request(
                `/groups/${id}`, 
                'DELETE'
            )
        );
    }
};

const groupService = new GroupService(process.env.REACT_APP_API_URL);
export default groupService;