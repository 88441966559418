// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-editor-panel_rulesEditorPanel__q6Z90 {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    height: 100%;
    width: 100%;

    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 1;
    background-color: #fefefe;

    transform: translateX(0);
    transition: transform 0.4s;
}

.rules-editor-panel_hidden__dDHc1 {
    transform: translateX(-100%);
}

.rules-editor-panel_rulesEditorPanel__q6Z90 > *:not(:first-child) {
    margin-top: 10px;
}

.rules-editor-panel_buttons__vgdmx {
    margin-top: 20px!important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rules-editor-panel_buttons__vgdmx > * {
    flex: 1 1;
}

.rules-editor-panel_buttons__vgdmx > *:not(:first-child) {
    margin-left: 10px;
}

.rules-editor-panel_input__fQ9X4 {
    width: 100%;
}

.rules-editor-panel_textArea__LF26u {
    width: 100%;
}

.rules-editor-panel_titleError__1jUFB {
    color: crimson;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-size: 14px;
}

.rules-editor-panel_statusWrapper__v\\+I4a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rules-editor-panel_statusLabel__TqIs- {
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/modules/rules-editor-panel/rules-editor-panel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;;IAEvB,YAAY;IACZ,WAAW;;IAEX,gBAAgB;;IAEhB,kBAAkB;IAClB,MAAM;IACN,OAAO;;IAEP,UAAU;IACV,yBAAyB;;IAEzB,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".rulesEditorPanel {\n    display: flex;\n    padding: 10px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n\n    height: 100%;\n    width: 100%;\n\n    overflow: hidden;\n\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    z-index: 1;\n    background-color: #fefefe;\n\n    transform: translateX(0);\n    transition: transform 0.4s;\n}\n\n.hidden {\n    transform: translateX(-100%);\n}\n\n.rulesEditorPanel > *:not(:first-child) {\n    margin-top: 10px;\n}\n\n.buttons {\n    margin-top: 20px!important;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.buttons > * {\n    flex: 1;\n}\n\n.buttons > *:not(:first-child) {\n    margin-left: 10px;\n}\n\n.input {\n    width: 100%;\n}\n\n.textArea {\n    width: 100%;\n}\n\n.titleError {\n    color: crimson;\n    text-align: center;\n    width: 100%;\n    display: inline-block;\n    font-size: 14px;\n}\n\n.statusWrapper {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.statusLabel {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rulesEditorPanel": `rules-editor-panel_rulesEditorPanel__q6Z90`,
	"hidden": `rules-editor-panel_hidden__dDHc1`,
	"buttons": `rules-editor-panel_buttons__vgdmx`,
	"input": `rules-editor-panel_input__fQ9X4`,
	"textArea": `rules-editor-panel_textArea__LF26u`,
	"titleError": `rules-editor-panel_titleError__1jUFB`,
	"statusWrapper": `rules-editor-panel_statusWrapper__v+I4a`,
	"statusLabel": `rules-editor-panel_statusLabel__TqIs-`
};
export default ___CSS_LOADER_EXPORT___;
