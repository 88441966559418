import React from "react";
import storageService from "../../services/storage.service";
import authService from "../../services/api/auth.service";
import styles from "./login-form.module.css";
import { useNavigate } from "react-router-dom";
import TextInputComponent from "../../components/text-input/text-input";
import ButtonComponent from "../../components/button/button";

export default function LoginForm() {
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [loggingIn, setLoggingIn] = React.useState(false);
    const [loginError, setLoginError] = React.useState(false);

    const onLoginSuccess = () => {
        storageService.set('token', authService.getActiveToken());
        navigate('/dashboard');
    };

    const onLoginClick = () => {
        setLoggingIn(true);
        setLoginError(false);
        authService.login(email, password).then(success => {
            if(success)
                return onLoginSuccess();

            setPassword('');
            setLoginError(true);
            setLoggingIn(false);
        });
    };

    React.useEffect(() => {
        const token = storageService.get('token');
        if(!token) return;

        setLoggingIn(true);
        authService.checkToken(token).then(success => {
            if(success) return onLoginSuccess();
            setLoggingIn(false);
        });
    }, []);

    return (
        <div className={styles.loginForm}>
            <div className={styles.header}>
                <h2>Connexion</h2>
            </div>
            <TextInputComponent
                type="email"
                value={email}
                onChange={e => setEmail(e)}
                placeholder="example@gmail.com"
                label="Adresse mail"
                disabled={loggingIn}
            />
            <TextInputComponent
                type="password"
                value={password}
                onChange={e => setPassword(e)}
                placeholder="●●●●●●●●"
                label="Mot de passe"
                disabled={loggingIn}
            />
            {
                loginError && (
                    <label className={styles.loginError}>Adresse mail ou mot de passe incorrect</label>
                )
            }
            <ButtonComponent
                className={styles.button}
                onClick={onLoginClick}
                color="dodgerblue"
                disabled={loggingIn}
            >
                Se connecter
            </ButtonComponent>
        </div>
    );
};