import * as React from "react";

import styles from "./rules-list.module.css";
import RuleItemComponent from "../../components/rule-item/rule-item";
import BlackScreenComponent from "../../components/black-screen/black-screen";
import TextInputComponent from "../../components/text-input/text-input";

export default function RulesList({
    rules,
    fetchError,
    disabled,
    onRuleEdit,
    onRuleRemove,
    onFetch,
    deleteable
}) {
    const [search, setSearch] = React.useState('');

    return (
        <div className={styles.rulesList}>
            <div className={styles.searchBar}>
                <TextInputComponent value={search} onChange={value => setSearch(value)} placeholder="Rechercher une règle..." />
                <hr />
            </div>
            <ul className={styles.list}>
                {
                    rules && rules.filter(rule => !search.length || rule.name.toLowerCase().includes(search.toLowerCase())).map(rule => (
                        <RuleItemComponent
                            key={`rule-item-${rule.id}`}
                            id={rule.id}
                            title={rule.name}
                            description={rule.description}
                            disabled={rule.disabled}
                            creationTime={(new Date(rule.created_at)).toLocaleString()}
                            updateTime={(new Date(rule.updated_at)).toLocaleString()}
                            onClick={() => onRuleEdit && onRuleEdit(rule)}
                            onRemoveClick={() => onRuleRemove && onRuleRemove(rule)}
                            deleteable={deleteable}
                        />
                    ))
                }
            </ul>
            <BlackScreenComponent show={false/*fetchError || disabled*/}>
                { fetchError && (
                    <label className={styles.fetchLabel}>
                        Impossible de récupérer les règles, veuillez <span className={styles.retryFetchLabel}>réessayer</span>.
                    </label>
                ) }
            </BlackScreenComponent>
        </div>
    );
};